.Welcome-wrapper {
  position: absolute;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  background-color: #4697a4;
  display: flex;
  color: white;
}

.Logo {
  user-select: none;
  position: fixed !important;
  width: 80%;
  margin: 10%;
}

.Welcome-heading {
  display: flex;
  align-items: stretch;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0;
  position: relative;
  margin: auto;
}
.Welcome-actions {
  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
}
.Welcome-actions a {
  color: #fff;
  background: transparent;
}

.Welcome-wrapper .content h2 {
  font-size: 3rem;
}

.Welcome-wrapper .content p {
  font-size: 1.5rem;
}
