body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.wrapper {
  position: absolute;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  background-color: white;
  display: flex;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu,
    Helvetica Neue, sans-serif;
  padding: 0 0.6 rem;
}

.content {
  max-width: 600px !important;
  margin: 0 auto;
  text-align: center;
  position: relative;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  width: 80%;
}
.wrapper button,
.wrapper a {
  min-width: 75%;
  background-color: #4697a4;
  font-size: 1.3rem;
  min-height: 3rem;
  min-width: 2.5rem;
  color: #fff;
  border-radius: 0.35rem;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 700;
  padding: 0 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: normal;
  font-stretch: normal;
  align-items: flex-start;
  line-height: 48px;
  font-size: 1.3rem;
  min-height: 3rem;
  min-width: 2.5rem;
}

.wrapper select,
.wrapper button,
.wrapper input,
.wrapper .Form-Margin {
  margin-bottom: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.wrapper select.empty {
  color: rgba(50, 50, 50);
  -webkit-text-fill-color: rgba(50, 50, 50, 0.4);
}

.wrapper select:disabled,
.wrapper input:disabled {
  background-color: rgb(245, 248, 250);
  color: rgb(50, 50, 50);
  -webkit-opacity: 0.7;
  -webkit-text-fill-color: rgb(50, 50, 50);
  -webkit-appearance: none;
  -moz-appearance: none;
}

.wrapper select {
  outline: none;
  border: none;
  background-color: rgb(245, 248, 250);
  color: rgba(0, 0, 0, 0.5);
  border-width: 0;
  padding: 0.65rem;
  resize: none;
  border-radius: 0.35rem;
  -webkit-text-fill-color: black;
  font: inherit;
}

.wrapper button:focus {
  outline: none;
}

.content button:disabled {
  background-color: #5abfd0;
  cursor: none;
}

.wrapper input {
  background-color: rgb(245, 248, 250);
  border-width: 0;
  padding: 0.65rem;
  resize: none;
  border-radius: 0.35rem;
  color: inherit;
  font: inherit;
}

.wrapper input:focus {
  outline: none;
}

.wrapper .form {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0;
  position: relative;
}
